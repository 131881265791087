import React, { useEffect, useRef, useState as state, forwardRef } from 'react';
import hive from "./index"
import { project } from "./project/project"
import { useState } from "@hookstate/core"
import * as THREETYPE from "three"
import { ReactSVG } from "react-svg"

declare const THREE: typeof THREETYPE;

export interface TourParam {
    startSceneIndex: number;
    transitionTime: number;
    inertiaFactor: number;
    sensibility: number;
    scenes: SceneParam[];
}

interface SceneParam {
    path: string;
    fov: Fov;
    view: View;
    elements: ElementParam[];
    compas?: number;
}

interface ElementParam {
    type: string;
    rotateY: number;
    rotateX: number;
    text: string;
    action: string;
    target?: string;
    id: string;
    pos: View;
    [key: string]: any;
}

interface InfoTipParam extends ElementParam {
    rotate: number;
    picture?: string;
    lineSize: number;
    width: number;
    height: number;
    textWidth: number;
    textHeight: number;
    borderRadius: number;
}

interface CruiseParam extends ElementParam {
    icon: string;
    aggr?: string;
}

interface View {
    lon: number;
    lat: number;
}

interface Fov {
    default: number;
    min: number;
    max: number;
}

function isTouchDevice() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        ((navigator as any).msMaxTouchPoints > 0));
}

interface Element {
    object: THREE.Object3D,
    parent: THREE.Object3D,
    params: ElementParam,
}

interface Scene {
    params: SceneParam;
    index: number;
    texture?: THREETYPE.Texture;
    material?: THREE.MeshBasicMaterial;
    materialPromise?: Promise<THREETYPE.Texture>;
    elements: Element[]
}

interface EditUpdate {
    selectedElement: ElementParam | null
}

interface EventUpdate {
    scale: number;
    loading: boolean;
    containerHeight: number;
}

class TourEngine {
    editMode = false;
    params: TourParam;
    destroyed = false;

    onUpdates: ((param: TourParam) => void)[] = [];
    onEditUpdates: ((param: EditUpdate) => void)[] = [];
    onEventUpdate: ((param: EventUpdate) => void)[] = [];
    eventUpdate: EventUpdate = {
        scale: 0.5,
        loading: true,
        containerHeight: 0,
    }

    scenes!: Scene[];
    activeScene!: Scene;
    nextActiveScene!: Scene;

    isTextureLoading = false;

    hammer!: HammerManager;
    listenerToRemove: {
        element: HTMLElement,
        func: Function,
        listener: keyof HTMLElementEventMap;
    }[] = [];

    geometry = new THREE.SphereGeometry(1900, 120, 80);
    scene = new THREE.Scene();
    nextScene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(90, 1, 1, 2000);
    bigFovCamera = new THREE.PerspectiveCamera(180, 1, 1, 2000);
    nextCamera = new THREE.PerspectiveCamera(90, 1, 1, 2000);
    gyroCamera = new THREE.PerspectiveCamera(90, 1, 1, 2000);
    mesh!: THREE.Mesh
    nextMesh!: THREE.Mesh
    renderer = new THREE.WebGLRenderer({ antialias: true });
    nextRenderer = new THREE.WebGLRenderer({ antialias: true });
    sceneNeedUpdate = true;

    cssRenderer = new (THREE as any).CSS3DRenderer();
    cssScene = new THREE.Scene();
    nextCssRenderer = new (THREE as any).CSS3DRenderer();
    nextCssScene = new THREE.Scene();

    parentContainer: HTMLElement;
    container: HTMLElement = document.createElement("div");
    nextContainer: HTMLElement = document.createElement("div");
    containerHeight = 0;

    inertiaX = 0;
    inertiaY = 0;

    selectedElement?: Element;

    compassCallback?: Function;

    gyro = false;
    constructor(container: HTMLElement, params: TourParam, editMode = false) {
        this.editMode = editMode;
        this.params = params;
        this.scenes = params.scenes.map((scene, i) => ({
            params: scene,
            index: i,
            elements: [],
        }))
        this.parentContainer = container;
        this.parentContainer.appendChild(this.container);
        this.parentContainer.appendChild(this.nextContainer);

        this.nextContainer.style.position = "absolute"
        this.nextContainer.style.top = "0px"

        this.nextContainer.style.transition = `opacity ${this.params.transitionTime}s`
        this.nextContainer.style.opacity = "0"
        this.nextContainer.style.overflow = "hidden";

        this.container.style.transition = `opacity ${this.params.transitionTime}s`
        this.container.style.opacity = "0"
        this.container.style.overflow = "hidden";

        this.containerHeight = this.parentContainer.clientHeight;

        this.gyro = window.location.search.indexOf("gyro") !== -1;
        // this.handleGyro();
    }

    lastWidth = -1;
    async watchSize() {
        window.onresize = () => {
            this.setViewportSize();
        }
        while (!this.destroyed) {
            await new Promise(r => setTimeout(r, 200));
            if (this.lastWidth !== window.outerWidth) {
                this.lastWidth = window.outerWidth;
                await new Promise(r => setTimeout(r, 500));
                this.setViewportSize();
            }
        }
    }

    updateParam() {
        for (let onUpdate of this.onUpdates) {
            onUpdate(this.params);
        }
    }

    setViewportSize() {
        this.renderer.setSize(this.parentContainer.clientWidth, this.parentContainer.clientHeight);
        this.camera.aspect = this.parentContainer.clientWidth / this.parentContainer.clientHeight;
        this.camera.updateProjectionMatrix();

        this.nextRenderer.setSize(this.parentContainer.clientWidth, this.parentContainer.clientHeight);
        this.nextCamera.aspect = this.parentContainer.clientWidth / this.parentContainer.clientHeight;
        this.nextCamera.updateProjectionMatrix();

        this.cssRenderer.setSize(this.container.clientWidth, this.container.clientHeight);
        this.nextCssRenderer.setSize(this.nextContainer.clientWidth, this.nextContainer.clientHeight);

        this.sceneNeedUpdate = true;
        this.containerHeight = this.parentContainer.clientHeight;
        this.updateEvent()
    }

    controls = {
        onPointerDownMouseX: 0,
        onPointerDownMouseY: 0,
        onPointerDownLon: 0,
        onPointerDownLat: 0,
        lon: 0,
        lat: 0,
    }

    placeElement(element: Element) {
        // element.params.pos.lat = Math.max(- 85, Math.min(85, element.params.pos.lat));
        const phi = THREE.MathUtils.degToRad(90 - element.params.pos.lat);
        const theta = THREE.MathUtils.degToRad(-element.params.pos.lon);
        const x = Math.sin(phi) * Math.cos(theta);
        const y = Math.cos(phi);
        const z = Math.sin(phi) * Math.sin(theta);
        element.object.rotation.y = THREE.MathUtils.degToRad(element.params.rotateY);
        element.object.rotation.x = THREE.MathUtils.degToRad(element.params.rotateX);

        element.object.position.y = -element.params.pos.lat * 4;

        element.parent.lookAt(x, 0, z);
    }

    vector3toLonLat(vector3: any) {

        vector3.normalize();

        //longitude = angle of the vector around the Y axis
        //-( ) : negate to flip the longitude (3d space specific )
        //- PI / 2 to face the Z axis
        var lng = -(Math.atan2(-vector3.z, -vector3.x)) - Math.PI / 2;

        //to bind between -PI / PI
        if (lng < - Math.PI) lng += Math.PI * 2;

        //latitude : angle between the vector & the vector projected on the XZ plane on a unit sphere

        //project on the XZ plane
        var p = new THREE.Vector3(vector3.x, 0, vector3.z);
        //project on the unit sphere
        p.normalize();

        //commpute the angle ( both vectors are normalized, no division by the sum of lengths )
        var lat = Math.acos(p.dot(vector3));

        //invert if Y is negative to ensure teh latitude is comprised between -PI/2 & PI / 2
        if (vector3.y < 0) lat *= -1;

        return [lng, lat];

    }

    updateControls() {
        if (this.selectedElement) {
            this.placeElement(this.selectedElement);
        } else {
            // Add gyro camera
            const lookAtVector = new THREE.Vector3(0, 0, -1);
            lookAtVector.applyQuaternion(this.gyroCamera.quaternion);
            const [lng, lat] = this.vector3toLonLat(lookAtVector)

            this.controls.lat = Math.max(- 85, Math.min(85, this.controls.lat));
            let phi = THREE.MathUtils.degToRad(90 - this.controls.lat);
            let theta = THREE.MathUtils.degToRad(this.controls.lon);
            phi -= lat;
            theta -= lng;

            let x = Math.sin(phi) * Math.cos(theta);
            let y = Math.cos(phi);
            let z = Math.sin(phi) * Math.sin(theta);

            this.camera.lookAt(x, y, z);
            this.bigFovCamera.lookAt(x, y, z);
        }
        this.sceneNeedUpdate = true;
    }

    handleEditControls() {
        document.onkeydown = (e) => {
            if (this.selectedElement) {
                const amount = 3;
                let proced = true;
                if (e.key === `ArrowRight`) {
                    this.selectedElement.params.rotateY += amount;
                } else if (e.key === `ArrowLeft`) {
                    this.selectedElement.params.rotateY += -amount;
                } else if (e.key === `ArrowUp`) {
                    this.selectedElement.params.rotateX += -amount;
                } else if (e.key === `ArrowDown`) {
                    this.selectedElement.params.rotateX += amount;
                } else if (e.key === "PageDown" || e.key === "PageUp") {
                    if (this.selectedElement.params.type === "infoTip") {
                        this.selectedElement.params.rotate! += e.key === "PageDown" ? 5 : -5;
                        this.onEditUpdates.forEach(func => {
                            func({
                                selectedElement: this.selectedElement!.params
                            })
                        })
                    }
                } else if (e.key === "+" || e.key === "-") {
                    if (this.selectedElement.params.type === "infoTip") {
                        this.selectedElement.params.lineSize! += e.key === "+" ? 5 : -5;
                        this.onEditUpdates.forEach(func => {
                            func({
                                selectedElement: this.selectedElement!.params
                            })
                        })
                    }
                }
                else {
                    proced = false;
                }
                if (proced) {
                    e.preventDefault();
                    this.placeElement(this.selectedElement);
                    this.sceneNeedUpdate = true;
                }
            }
        };
    }

    updateEvent() {
        this.onEventUpdate.forEach((onEventUpdate) => {
            onEventUpdate({
                scale: this.activeScene ? this.camera.fov / this.activeScene.params.fov.max : 1,
                loading: this.eventUpdate.loading,
                containerHeight: this.containerHeight
            })
        })
    }

    async handleGyro() {
        if ((DeviceOrientationEvent as any).requestPermission) {
            const res = await (DeviceOrientationEvent as any).requestPermission()
        }

        const setQuaternion = (() => {
            const zee = new THREE.Vector3(0, 0, 1);
            const euler = new THREE.Euler();
            const q0 = new THREE.Quaternion();
            const q1 = new THREE.Quaternion(-1 * Math.sqrt(0.5), 0, 0, Math.sqrt(0.5));

            return (alpha: number, beta: number, gamma: number, orientation: number) => {
                euler.set(beta, alpha, -1 * gamma, "YXZ");

                this.gyroCamera.quaternion.setFromEuler(euler);
                this.gyroCamera.quaternion.multiply(q1);
                this.gyroCamera.quaternion.multiply(q0.setFromAxisAngle(zee, -1 * orientation));

                // this.bigFovCamera.quaternion.setFromEuler(euler);
                // this.bigFovCamera.quaternion.multiply(q1);
                // this.bigFovCamera.quaternion.multiply(q0.setFromAxisAngle(zee, -1 * orientation));
                // this.sceneNeedUpdate = true;
                this.updateControls();
            };
        })();

        const list = (e: any) => {
            // this.controls.lon = -(e.alpha as number);
            let event = e as any;

            let alpha = event.alpha * (Math.PI / 180);
            let beta = event.beta * (Math.PI / 180);
            let gamma = event.gamma * (Math.PI / 180);


            let orientation = (window as any).orientation * (Math.PI / 180);
            setQuaternion(alpha, beta, gamma, orientation);

        }
        window.addEventListener("deviceorientation", list, true);
        this.listenerToRemove.push({
            element: window as any,
            func: list,
            listener: "wheel"
        })

    }

    async handleControls() {
        // if (this.gyro) {
        //     return;
        // }
        const setFov = (fov: number) => {
            this.camera.fov = fov;
            this.camera.updateProjectionMatrix();
            this.sceneNeedUpdate = true;
            this.updateEvent();
        }

        const wheelEvent = (event: WheelEvent) => {
            event.preventDefault();
            const newFov = this.camera.fov + (event.deltaY > 0 ? 2.5 : -2.5);
            if (newFov > this.activeScene.params.fov.min && newFov < this.activeScene.params.fov.max) {
                setFov(newFov);
            }
        }
        this.parentContainer.addEventListener("wheel", wheelEvent)
        this.listenerToRemove.push({
            element: this.parentContainer,
            func: wheelEvent,
            listener: "wheel"
        })

        this.hammer = new Hammer(this.parentContainer);
        this.hammer.get("pinch").set({ enable: true });
        this.hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });

        let actualFov = this.camera.fov;
        this.hammer.on("pinchstart", () => {
            actualFov = this.camera.fov;
        })

        this.hammer.on("tap", (e) => {
            if (this.editMode && this.selectedElement) {
                this.unselectElement();
            }
        })

        this.hammer.on("pinch", (e) => {
            const scale = 1 - e.scale;
            const newFov = actualFov + (scale * 40);

            if (newFov > this.activeScene.params.fov.min && newFov < this.activeScene.params.fov.max) {
                setFov(newFov)
            }
        })

        this.hammer.on("panstart", (event) => {
            this.controls.onPointerDownMouseX = event.center.x;
            this.controls.onPointerDownMouseY = event.center.y;

            if (this.selectedElement) {
                this.controls.onPointerDownLon = this.selectedElement.params.pos.lon;
                this.controls.onPointerDownLat = this.selectedElement.params.pos.lat;
            } else {
                this.controls.onPointerDownLon = this.controls.lon;
                this.controls.onPointerDownLat = this.controls.lat;
            }
        })

        this.hammer.on("panmove", (event) => {
            const impact = (1920 / window.innerWidth * 0.1) * this.params.sensibility;
            if (this.selectedElement) {
                this.selectedElement.params.pos.lon = (this.controls.onPointerDownMouseX - event.center.x) * (impact) + this.controls.onPointerDownLon;
                this.selectedElement.params.pos.lat = (event.center.y - this.controls.onPointerDownMouseY) * (impact) + this.controls.onPointerDownLat;
            } else {
                this.controls.lon = (this.controls.onPointerDownMouseX - event.center.x) * (impact) + this.controls.onPointerDownLon;
                this.controls.lat = (event.center.y - this.controls.onPointerDownMouseY) * (impact) + this.controls.onPointerDownLat;
            }
            this.updateControls();
        })

        this.hammer.on("panend", async (event) => {
            if (!this.selectedElement) {
                this.inertiaX = -event.velocityX * this.params.inertiaFactor;
                this.inertiaY = event.velocityY * this.params.inertiaFactor;
            }
        })
    }

    async mainLoop() {
        while (!this.destroyed) {
            if (this.inertiaX !== 0 || this.inertiaY !== 0) {
                this.controls.lon += this.inertiaX;
                this.controls.lat += this.inertiaY;
                this.inertiaX *= 0.9;
                this.inertiaY *= 0.9;
                if (Math.abs(this.inertiaX) < 0.1) {
                    this.inertiaX = 0;
                }
                if (Math.abs(this.inertiaY) < 0.1) {
                    this.inertiaY = 0;
                }
                this.updateControls();
            }
            if (this.sceneNeedUpdate) {
                this.sceneNeedUpdate = false;
                this.renderer.render(this.scene, this.camera);
                this.cssRenderer.render(this.cssScene, this.camera);
                if (this.compassCallback) {
                    this.compassCallback(this.controls.lon)
                }

                const isElementInFrontOfCamera = (el: Element) => {
                    this.bigFovCamera.updateMatrix();
                    this.bigFovCamera.updateMatrixWorld();
                    // this.bigFovCamera.matrixWorldInverse.getInverse(this.bigFovCamera.matrixWorld);
                    new THREE.Matrix3().invert()
                    var frustum = new THREE.Frustum();
                    const projectionMatrix = new THREE.Matrix4().multiplyMatrices(this.bigFovCamera.projectionMatrix, this.bigFovCamera.matrixWorldInverse);
                    frustum.setFromProjectionMatrix(projectionMatrix);
                    let targetPosition = new THREE.Vector3();
                    targetPosition = targetPosition.setFromMatrixPosition(el.object.matrixWorld);
                    return frustum.containsPoint(new THREE.Vector3(targetPosition.x, targetPosition.y, targetPosition.z))
                }

                for (let el of this.activeScene.elements.filter(e => e.params.type === "infoTip")) {
                    const res = (document.getElementById(el.params.id) as HTMLElement).getBoundingClientRect();
                    const resParent = this.parentContainer.getBoundingClientRect();

                    res.y -= resParent.y;
                    res.x -= resParent.x;
                    const display = (document.getElementById(`${el.params.id}_display`) as HTMLElement)
                    if (isElementInFrontOfCamera(el)) {
                        display.style.display = "initial";
                    } else {
                        display.style.display = "none";
                    }

                    const left = res.x - (el.params.width / 2)
                    const top = res.y - (el.params.lineSize / 1 + el.params.height / 1) + 5
                    display.style.top = `${top}px`;
                    display.style.left = `${left}px`;
                }
            }
            await new Promise(r => requestAnimationFrame(r));
        }
    }

    async backgroundTextureLoader() {
        for (let scene of this.scenes) {
            while (this.isTextureLoading) {
                await new Promise(r => setTimeout(r, 100))
            }
            if (this.destroyed) {
                break;
            }
            await this.preloadTexture(scene);
        }
    }

    async preloadTexture(scene: Scene) {
        if (!scene.material) {
            const baseMaterialPromise = scene.materialPromise;
            if (!scene.materialPromise) {
                this.isTextureLoading = true;
                scene.materialPromise = new Promise<THREETYPE.Texture>(resolve => {
                    new THREE.TextureLoader().load(scene.params.path, (texture) => {
                        resolve(texture);
                    });
                })
            }
            const texture = await scene.materialPromise;
            if (!baseMaterialPromise) {
                this.isTextureLoading = false;

                // preload in GPU
                scene.material = new THREE.MeshBasicMaterial({ map: texture });
                scene.texture = texture;
                const tmpMaterial = this.nextMesh.material;
                this.nextMesh.material = scene.material;
                this.nextRenderer.render(this.nextScene, this.nextCamera);
                this.nextMesh.material = tmpMaterial;
            }
        }
    }

    preloadNextCss() {
        for (let child of this.nextCssScene.children) {
            // this.nextCssScene.remove(child);
        }
        for (let el of this.nextActiveScene.elements) {
            this.nextCssScene.add(el.object);
            this.nextCssScene.add(el.parent);
            el.object.parent = el.parent;
        }
    }

    async prepareScene(index: number) {
        this.eventUpdate.loading = true;
        this.updateEvent()
        this.nextActiveScene = this.scenes[index]

        await this.preloadTexture(this.nextActiveScene)
        this.preloadNextCss()
        this.nextMesh.material = this.nextActiveScene.material!;
        for (let element of this.nextActiveScene.elements) {
            if (element.params.type === "infoTip") {
                const display = (document.getElementById(`${element.params.id}_display`) as HTMLElement);
                this.nextContainer.appendChild(display);
            }
            this.placeElement(element);
        }
        this.eventUpdate.loading = false;
        this.updateEvent()
    }

    async setScene(index: number) {
        await this.prepareScene(index)
        await this.applyNextScene()
    }

    setStartView() {
        this.activeScene.params.view.lat = this.controls.lat;
        this.activeScene.params.view.lon = this.controls.lon;
    }

    async applyNextScene() {
        if (this.selectedElement) {
            this.unselectElement();
        }
        const tmpMesh = this.mesh;
        const tmpScene = this.scene;
        const tmpCamera = this.camera;
        const tmpRenderer = this.renderer;
        const tmpContainer = this.container;
        const tmpActiveScene = this.activeScene;
        const tmpCssRenderer = this.cssRenderer;
        const tmpCssScene = this.cssScene;

        this.mesh = this.nextMesh;
        this.renderer = this.nextRenderer
        this.scene = this.nextScene
        this.camera = this.nextCamera;
        this.container = this.nextContainer;
        this.activeScene = this.nextActiveScene;
        this.cssRenderer = this.nextCssRenderer;
        this.cssScene = this.nextCssScene;

        this.nextMesh = tmpMesh;
        this.nextScene = tmpScene;
        this.nextCamera = tmpCamera;
        this.nextRenderer = tmpRenderer;
        this.nextContainer = tmpContainer;
        this.nextActiveScene = tmpActiveScene;
        this.nextCssRenderer = tmpCssRenderer;
        this.nextCssScene = tmpCssScene;

        this.sceneNeedUpdate = true;

        this.controls.lat = this.activeScene.params.view.lat;
        this.controls.lon = this.activeScene.params.view.lon;
        this.controls.onPointerDownLat = 0;
        this.controls.onPointerDownLon = 0;
        this.camera.fov = this.activeScene.params.fov.default;
        this.updateEvent();
        this.camera.updateProjectionMatrix();
        this.updateControls();
        this.container.style.opacity = '1';
        this.nextContainer.style.opacity = '0';

        this.renderer.render(this.scene, this.camera);
        this.cssRenderer.render(this.cssScene, this.camera);
        for (let element of this.activeScene.elements) {
            const domElement = (document.querySelector(`#${element.params.id}`) as HTMLElement);
            domElement.style.pointerEvents = 'initial';
            domElement.style.visibility = 'initial';
        }
        if (this.nextActiveScene) {
            for (let element of this.nextActiveScene.elements) {
                const domElement = (document.querySelector(`#${element.params.id}`) as HTMLElement);
                domElement.style.pointerEvents = 'none';
                domElement.style.visibility = 'hidden';
            }
        }
        for (let element of this.activeScene.elements) {
            if (element.params.aggr && !this.editMode) {
                for (let id of element.params.aggr.split("\n")) {
                    const el = document.getElementById(id) as HTMLElement;
                    el.style.opacity = "0";
                    el.style.pointerEvents = "none";
                }
                const el = document.getElementById(element.params.id) as HTMLElement;
                el.style.pointerEvents = "initial";
                el.style.opacity = "1";
            }
        }
    }

    setCssObjects() {
        for (let scene of this.scenes) {
            scene.elements = scene.params.elements.map(el => {
                const found = scene.elements.find(e => e.params === el)
                if (!found) {
                    const object = (new (THREE as any).CSS3DObject(document.querySelector(`#${el.id}`)) as THREE.Object3D)
                    const parent = new THREE.Object3D();
                    object.translateZ(-400);
                    return {
                        object,
                        params: el,
                        parent,
                    }
                } else {
                    return found
                }
            })
        }
    }

    executeElement(elementId: string) {
        const element = this.activeScene.elements.find(e => e.params.id === elementId) as Element;
        if (!element) return;
        if (this.editMode) {
            this.selectElement(element);
        } else {
            if (element.params.action === "cruise") {
                if (element.params.aggr) {
                    for (let id of element.params.aggr.split("\n")) {
                        const el = document.getElementById(id) as HTMLElement;
                        el.style.opacity = "1";
                        el.style.pointerEvents = "initial";
                    }
                    const el = document.getElementById(element.params.id) as HTMLElement;
                    el.style.opacity = "0";
                    el.style.pointerEvents = "none";
                } else {
                    const target = this.scenes.find(scene => scene.params.path === element.params.target) as Scene
                    this.setScene(this.scenes.findIndex(s => s === target));
                }
            }
        }
    }

    async saveChanges() {
        hive.saveTours();
    }

    async createElement(type: string) {
        const initElement = (type: string): ElementParam => {
            if (type === "cruise") {
                return {
                    action: "cruise",
                    id: `hot_${Date.now()}`,
                    pos: {
                        lat: 0,
                        lon: 0,
                    },
                    type: "cruise",
                    target: "/tours/T3/kitchen.jpg",
                    text: "Le texte",
                    rotateY: 0,
                    rotateX: 0,
                }
            } else if (type === "infoTip") {
                return {
                    action: "none",
                    id: `hot_${Date.now()}`,
                    pos: {
                        lat: 0,
                        lon: 0,
                    },
                    type: "infoTip",
                    picture: "/tours/assets/base_picto.jpg",
                    text: "Le texte",
                    rotateY: 0,
                    rotateX: 0,
                    lineSize: 50,
                    rotate: 0,
                    borderRadius: 30,
                    width: 60,
                    height: 60,
                    textHeight: 40,
                    textWidth: 150,
                } as InfoTipParam
            } else {
                return {
                    action: "cruise",
                    id: `hot_${Date.now()}`,
                    pos: {
                        lat: 0,
                        lon: 0,
                    },
                    type: "cruise",
                    target: "/tours/T3/kitchen.jpg",
                    text: "Le texte",
                    rotateY: 0,
                    rotateX: 0,
                }
            }
        }
        const elementParam = initElement(type);
        this.params.scenes.find(sceneParam => this.activeScene.params === sceneParam)
            ?.elements.push(elementParam)
        this.updateParam();
        await new Promise(r => setTimeout(r, 200))
        this.setCssObjects();
        const element = this.activeScene.elements.find(e => e.params === elementParam)
        this.cssScene.add(element!.object);
        this.cssScene.add(element!.parent);
        element!.object.parent = element!.parent;
        this.placeElement(element!);
        this.cssRenderer.render(this.cssScene, this.camera);
        this.sceneNeedUpdate = true;
    }

    editSelectedElement(params: Element["params"]) {
        this.selectedElement!.params.target = params.target
        this.selectedElement!.params.text = params.text
        this.selectedElement!.params.icon = params.icon
        this.selectedElement!.params.aggr = params.aggr
        this.selectedElement!.params.picture = params.picture
        this.selectedElement!.params.width = params.width
        this.selectedElement!.params.height = params.height
        this.selectedElement!.params.textWidth = params.textWidth
        this.selectedElement!.params.textHeight = params.textHeight
        this.selectedElement!.params.borderRadius = params.borderRadius;

        this.onEditUpdates.forEach(func => {
            func({
                selectedElement: params
            })
        })
    }

    unselectElement() {
        const htmlElement = document.querySelector(`#${this.selectedElement?.params.id}`) as HTMLElement;
        htmlElement.style.opacity = "1";
        htmlElement.style.border = "none";
        this.selectedElement = undefined;
        this.onEditUpdates.forEach(func => {
            func({
                selectedElement: null
            })
        })
    }

    selectElement(element: Element) {
        const htmlElement = document.querySelector(`#${element.params.id}`) as HTMLElement;
        htmlElement.style.opacity = "0.5";
        htmlElement.style.border = "2px solid red";
        this.selectedElement = element;
        this.onEditUpdates.forEach(func => {
            func({
                selectedElement: element.params
            })
        })
    }

    async init() {
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.nextRenderer.setPixelRatio(window.devicePixelRatio)


        this.geometry.scale(- 1, 1, 1);

        this.mesh = new THREE.Mesh(this.geometry);
        this.nextMesh = new THREE.Mesh(this.geometry);

        this.scene.add(this.mesh);
        this.nextScene.add(this.nextMesh);

        this.container.appendChild(this.renderer.domElement);
        this.nextContainer.appendChild(this.nextRenderer.domElement);

        this.setViewportSize();
        this.handleControls();
        this.watchSize();

        this.setCssObjects();

        this.cssRenderer.domElement.style.position = 'absolute';
        this.cssRenderer.domElement.style.top = 0;
        this.container.appendChild(this.cssRenderer.domElement);

        this.nextCssRenderer.domElement.style.position = 'absolute';
        this.nextCssRenderer.domElement.style.top = 0;
        this.nextContainer.appendChild(this.nextCssRenderer.domElement);

        this.container.style.pointerEvents = "none";
        this.nextContainer.style.pointerEvents = "none";

        await this.prepareScene(this.params.startSceneIndex);
        if (this.destroyed) return;
        await this.applyNextScene();

        this.backgroundTextureLoader();
        if (this.editMode) {
            this.handleEditControls()
        }
        this.mainLoop();
    }

    async destroy() {
        const bank = (document.querySelector(`#elementsBank`) as HTMLElement)
        try {
            this.hammer.off("pinchstart")
            this.hammer.off("tap")
            this.hammer.off("pinch")
            this.hammer.off("panstart")
            this.hammer.off("panmove")
            this.hammer.off("panend")
            document.onkeydown = null;
            window.onresize = null;
            this.onEventUpdate = [];
            this.onEditUpdates = [];
        } catch (e) {
            console.log(e);
        }

        try {
            for (let listener of this.listenerToRemove) {
                listener.element.removeEventListener(listener.listener, listener.func as any)
            }
        } catch (e) {
            console.log(e);
        }

        try {
            for (let scene of this.scenes) {
                try {
                    scene.texture!.dispose();
                    scene.material!.dispose();
                } catch (e) { }
                for (let element of scene.elements) {
                    const domElement = (document.querySelector(`#${element.params.id}`) as HTMLElement)
                    bank.appendChild(domElement);
                }
            }
        } catch (e) {
            console.log(e);
        }

        try {
            this.renderer.dispose();
            this.nextRenderer.dispose();
            this.geometry.dispose();
            this.mesh.remove();
            this.nextScene.remove(this.nextMesh);
            this.nextMesh.remove();
            this.container.remove()
            this.nextContainer.remove()
        } catch (e) {
            console.log(e);
        }
        this.destroyed = true;
    }
}

function InfoTip(p: { el: InfoTipParam, executeElement: Function, eventUpdate: EventUpdate, mainColor: string }) {
    const [hover, setHover] = state(false);
    const [clicked, setClicked] = state(false);

    const getSizeScale = () => (p.eventUpdate.containerHeight / 1080 * 1.2)
    // const getSizeScale = () => (1)

    return <div
        id={`${p.el.id}_display`}
        style={{
            width: 0,
            height: 0,
            position: "absolute",
            zIndex: hover ? 9999 : "initial",
        }}
    >
        <div style={{
            transformOrigin: `${p.el.width / 2}px ${(p.el.lineSize / 1 + p.el.height / 1) + 5}px`,
            transform: `rotate(${p.el.rotate}deg) ${hover ? `scale(${1.2 * getSizeScale()})` : `scale(${1 * getSizeScale()})`}`,
            // transform: `rotate(${p.el.rotate}deg)`,
            willChange: "transform",
            transition: "all 0.6s",
            cursor: "pointer",
            width: `${p.el.width}px`,
            height: `${p.el.height}px`,
        }}

        >
            <div style={{
                width: "100%",
                height: "100%",
                borderRadius: `${p.el.borderRadius}px`,
                border: `1px solid #${p.mainColor}`,
                background: "white",
                transform: `rotate(${-p.el.rotate!}deg)`,
                backgroundImage: p.el.picture ? `url(${p.el.picture})` : "none",
                backgroundSize: "80%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                pointerEvents: "auto",
            }}
                onMouseEnter={() => {
                    setHover(true)

                }}
                onMouseLeave={() => {
                    setHover(false)
                    setClicked(false);
                }}
                onClick={() => {
                    if (isTouchDevice()) {
                        if (clicked) {
                            setClicked(false);
                            p.executeElement()
                        } else {
                            // setHover(true)
                            setClicked(true);
                        }
                    } else {
                        p.executeElement()
                    }
                }}
            >
                {!p.el.picture && <>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        color: `#${p.mainColor}`,
                        height: "100%",
                        width: "100%",
                    }}>
                        <div style={{
                            textAlign: "center",
                            lineHeight: "1rem",
                            fontWeight: "bold",
                        }}>
                            {p.el.text}
                        </div>
                    </div>
                </>}

                {p.el.picture && <>
                    <div style={{
                        position: "absolute",
                        top: `${-(p.el.textHeight + 5)}px`,
                        left: `${-(p.el.textWidth / 2) + p.el.width / 2}px`,
                        width: `${p.el.textWidth}px`,
                        height: `${p.el.textHeight}px`,
                        pointerEvents: "none",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        color: `#${p.mainColor}`,
                        transition: "opacity 0.4s",
                        opacity: hover ? "1" : "0",
                        background: "rgba(255,255,255,1)",
                        borderRadius: "5px",
                    }}>
                        <div style={{
                            textAlign: "center",
                            lineHeight: "1rem",
                            fontWeight: "bold",
                        }}>
                            {p.el.text}
                        </div>
                    </div>
                </>}

            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
            }}>
                <div style={{
                    width: 2,
                    height: `${p.el.lineSize}px`,
                    backgroundColor: `#${p.mainColor}`,
                    outline: "1px solid transparent"
                }}>
                </div>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
            }}>
                <div style={{
                    backgroundColor: `#${p.mainColor}`,
                    width: "8px",
                    height: "8px",
                    borderRadius: "4px",
                    backfaceVisibility: "hidden",
                }}>
                </div>
            </div>


        </div>
    </div >
}

function Cruise(p: { el: CruiseParam, executeElement: Function, mainColor: string }) {
    const [hover, setHover] = state(false);
    const [clicked, setClicked] = state(false);
    const hasBeenClicked = useRef<Boolean>()

    useEffect(() => {
        hasBeenClicked.current = false;
        return () => {
            hasBeenClicked.current = false;
        }
    }, [])

    return <div style={{
        transition: "opacity 0.25s"
    }}
        id={p.el.id}
    >
        {p.el.text && <div style={{
            width: "100px",
            height: "25px",
            background: "white",
            borderRadius: "7px",
            marginBottom: "5px",
            textAlign: "center",
            lineHeight: "25px",
            opacity: hover ? "1" : "0",
            transition: "opacity 0.15s",
            color: `#${p.mainColor}`,
        }}>
            {p.el.text}
        </div>
        }
        <div style={{
            display: "flex",
            justifyContent: "center",
        }}>
            <div style={{
                width: "65px",
                height: "65px",
                cursor: "pointer",
                // overflow: "hidden",
                background: "white",
                borderRadius: "40px",
            }}
                onClick={() => {
                    if (!p.el.aggr) {
                        p.executeElement();
                        setClicked(true)
                        hasBeenClicked.current = true;
                        setTimeout(() => {
                            if (hasBeenClicked.current) {
                                setClicked(false);
                                hasBeenClicked.current = false;
                            }
                        }, 1000)
                    } else {
                        p.executeElement();
                    }
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div style={{
                    color: `#${p.mainColor}`,
                    pointerEvents: "none",
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <div style={{
                            width: "50%",
                            marginTop: "-6px",
                            opacity: hover ? "0.1" : "1",
                            transition: "opacity 0.15s",
                        }}>
                            <svg
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                                version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 330.002 180.002">
                                <path style={{
                                    fill: `#${p.mainColor}`,
                                }} id="XMLID_105_" d="M324.001,209.25L173.997,96.75c-5.334-4-12.667-4-18,0L6.001,209.25c-6.627,4.971-7.971,14.373-3,21
	c2.947,3.93,7.451,6.001,12.012,6.001c3.131,0,6.29-0.978,8.988-3.001L164.998,127.5l141.003,105.75c6.629,4.972,16.03,3.627,21-3
	C331.972,223.623,330.628,214.221,324.001,209.25z"/>
                            </svg>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <div style={{
                            width: "50%",
                            height: "50%",
                        }}>
                            <ReactSVG beforeInjection={(svg) => {
                                Array.from(svg.getElementsByClassName("cls-1")).forEach(el => {
                                    (el as any).style.fill = `#${p.mainColor}`;
                                })
                            }} src={`/tours/assets/cruise/${p.el.icon}.svg`}></ReactSVG>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
}

export function TourEmbed(props: { tourId: string, mainColor: string, compas: Function }) {
    const engine = useRef<TourEngine>()
    const [param, setParam] = state({ ...hive.tours[props.tourId] });
    const [edit, setEdit] = state<EditUpdate>({ selectedElement: null });
    const [eventUpdate, setEventUpdate] = state<EventUpdate>({ scale: 1, loading: true, containerHeight: 0, });
    const [gyroCheck, setGyroCheck] = state(false);

    useEffect(() => {
        (async () => {
            if (!hive.tours[props.tourId]) return;

            setParam({ ...hive.tours[props.tourId] });
            await new Promise(r => setTimeout(r, 0));
            const container = document.querySelector("#tourScene") as HTMLElement;
            engine.current = new TourEngine(container, hive.tours[props.tourId], window.location.search.endsWith("edit"));
            engine.current.onEventUpdate.push((eventUpdate) => {
                setEventUpdate({ ...eventUpdate });
            })
            engine.current.onEditUpdates.push((editUpdate) => {
                setEdit({ ...editUpdate });
            })
            engine.current.onUpdates.push((params) => {
                setParam({ ...params })
            })
            engine.current.compassCallback = (nbr: number) => {
                if (engine.current?.activeScene.params.compas) {
                    props.compas(nbr + engine.current.activeScene.params.compas)
                }
            }
            await engine.current.init();
        })();
        return () => {
            if (engine.current) {
                engine.current.destroy();
                engine.current = undefined;
            }
        }
    }, [props.tourId])

    return <><div style={{
        width: "100%",
        height: "100%",
    }}
        id="tourContainer"
    >
        {(engine.current?.gyro && !gyroCheck) && <div style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            zIndex: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            cursor: 'pointer',
        }}
            onClick={() => {
                engine.current?.handleGyro();
                setGyroCheck(true);
            }}
        >
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
            }}>
                <div style={{
                    width: "12vw",
                    height: "12vw",
                    backgroundImage: "url(/gyro_rotation.svg)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                }}>
                </div>
            </div>
            <div style={{
                color: "white",
                // width: "50%",
                display: "flex",
                justifyContent: "center",
                marginTop: "1vw",
            }}>
                <div style={{
                    textAlign: "center",
                    fontSize: "1.4rem",
                    fontWeight: "lighter",
                }}>
                    Visualisez le projet Les mas de Favard <br />
                    Dans son environnement 360°
                </div>
            </div>
            <div style={{
                color: "white",
                // width: "50%",
                display: "flex",
                justifyContent: "center",
            }}>
                <div id="chien" style={{
                    marginTop: "5vw",
                    border: "1px solid white",
                    width: "10rem",
                    height: "1.6rem",
                    lineHeight: "1.6rem",
                    borderRadius: "0.8rem",
                    display: "flex",
                }}>
                    <div style={{
                        width: "80%",
                        textAlign: "center",
                    }}>
                        Découvrir
                    </div>
                    <div style={{
                        width: "20%",
                        height: "1.6rem",
                        backgroundImage: "url(/gyro_play.svg)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "60% 60%",
                        backgroundPosition: "center",
                    }}>
                    </div>

                </div>
            </div>

        </div>
        }

        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            display: "flex",
            justifyContent: "center",
            visibility: "hidden",
            // alignContent: "center",
        }}>
            <div style={{
                width: "5%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}>
                <div style={{
                    width: "100%",
                    height: "10%",
                    backgroundImage: "url(/tour_loading.svg)",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    visibility: eventUpdate.loading ? "initial" : "hidden",
                    zIndex: 1,
                }}>
                </div>
            </div>
        </div>
        <div style={{
            width: "100%",
            height: "100%",
            // overflow: "hidden"
        }} id="tourScene">
        </div>
    </div>
        <br />
        {window.location.search.endsWith("edit") && <>
            {edit.selectedElement && <div style={{
                position: "absolute",
                width: 350,
                // height: 200,
                background: "white",
                top: 0,
            }}>
                <div>
                    {edit.selectedElement.id}<br />
                    {edit.selectedElement.target && <>
                        Target : <select
                            value={edit.selectedElement?.target}
                            onChange={(e) => {
                                engine.current?.editSelectedElement({ ...edit.selectedElement!, target: e.target.value })
                            }}
                        >
                            {param.scenes.map((scene: any) => <option
                                key={scene.path}>
                                {scene.path}
                            </option>)}
                        </select><br />
                    </>}
                    text : <input type="text" value={edit.selectedElement.text}
                        onChange={(e) => {
                            engine.current?.editSelectedElement({ ...edit.selectedElement!, text: e.target.value })
                        }}
                    >
                    </input><br />
                    icon :<input type="text" value={edit.selectedElement.icon}
                        onChange={(e) => {
                            engine.current?.editSelectedElement({ ...edit.selectedElement!, icon: e.target.value })
                        }}
                    >
                    </input><br />

                    {edit.selectedElement.type === "cruise" && <>
                        {["Salon", "Chambre", "Chambre 1", "Chambre 2", "Chambre 3", "Chambre 4", "Cuisine", "Salle d'eau", "Salle de bain"].map(e => (
                            <button key={e} onClick={event => {
                                engine.current?.editSelectedElement({ ...edit.selectedElement!, text: e })
                            }}>
                                {e}
                            </button>))}
                    </>}
                    <br />
                    aggr :<br /><textarea style={{ height: 200 }} value={edit.selectedElement.aggr}
                        onChange={(e) => {
                            engine.current?.editSelectedElement({ ...edit.selectedElement!, aggr: e.target.value })
                        }}
                    >
                    </textarea><br />

                    {edit.selectedElement.type === "infoTip" && <>
                        pic :<input type="text" value={edit.selectedElement.picture}
                            onChange={(e) => {
                                engine.current?.editSelectedElement({ ...edit.selectedElement!, picture: e.target.value })
                            }}
                        >
                        </input><br />
                        size :<input style={{
                            width: 40,
                        }} type="text" value={edit.selectedElement.width}
                            onChange={(e) => {
                                engine.current?.editSelectedElement({ ...edit.selectedElement!, width: e.target.value })
                            }}
                        >
                        </input>
                        <input style={{
                            width: 40,
                        }} type="text" value={edit.selectedElement.height}
                            onChange={(e) => {
                                engine.current?.editSelectedElement({ ...edit.selectedElement!, height: e.target.value })
                            }}
                        >
                        </input>
                        <br />
                        text size :<input style={{
                            width: 40,
                        }} type="text" value={edit.selectedElement.textWidth}
                            onChange={(e) => {
                                engine.current?.editSelectedElement({ ...edit.selectedElement!, textWidth: e.target.value })
                            }}
                        >
                        </input>
                        <input style={{
                            width: 40,
                        }} type="text" value={edit.selectedElement.textHeight}
                            onChange={(e) => {
                                engine.current?.editSelectedElement({ ...edit.selectedElement!, textHeight: e.target.value })
                            }}
                        >
                        </input>
                        <br />

                        border-radius<input style={{
                            width: 40,
                        }} type="text" value={edit.selectedElement.borderRadius}
                            onChange={(e) => {
                                engine.current?.editSelectedElement({ ...edit.selectedElement!, borderRadius: e.target.value })
                            }}
                        >
                        </input><br />



                        pageUp pagedown : rotate <br />
                        + - : line size
                    </>}

                </div>

            </div>
            }

            <div style={{
                width: "100%",
                border: "1px solid black",
            }}>
                <div style={{
                    display: "flex",
                }}>
                    {param.scenes.map((scene: any, i: number) => <div key={i}>
                        <button onClick={() => { engine.current?.setScene(i) }}>
                            {scene.path}
                        </button>
                    </div>
                    )}
                </div>
                <div>
                    <button onClick={() => { engine.current?.setStartView() }}>
                        set default view
                    </button>
                    <button onClick={() => { engine.current?.createElement("cruise") }}>
                        Add Room cruise
                    </button>
                    <button onClick={() => { engine.current?.createElement("infoTip") }}>
                        Add Info Tip
                    </button>
                    <br />
                    <button onClick={() => { engine.current?.saveChanges() }}>
                        Save changes
                    </button>
                </div>
            </div>
        </>
        }

        <div style={{
            display: "none",
        }}
            id="elementsBank"
        >
            {param.scenes.map((scene: any, y: number) => {
                return scene.elements.map((el: any, i: number) => {
                    if (el.type === "cruise") {
                        return <Cruise
                            mainColor={props.mainColor}
                            executeElement={() => {
                                engine.current?.executeElement(el.id);
                            }}
                            key={el.id} el={el}
                        ></Cruise>
                    } else if (el.type === "infoTip") {
                        return <div
                            key={el.id}
                            id={`${el.id}_bank`}
                        >
                            <div
                                id={el.id}
                                style={{
                                    width: "1px",
                                    height: "1px",
                                }}
                            >
                            </div>

                            <InfoTip mainColor={props.mainColor} eventUpdate={eventUpdate} executeElement={() => {
                                engine.current?.executeElement(el.id);
                            }} key={`${el.id}_item`} el={el as InfoTipParam}></InfoTip>
                        </div>
                    }
                })
            })}
        </div>
    </>
}

function Tour() {
    const { width, height, mainColor, tour, activeScene, compasStart } = useState(hive.state)

    const setCompass = (angle: number) => {
        compasStart.set(angle)
    }

    return <><div style={{
        background: "white",
        position: "absolute",
        height: height.get() + 'px',
        width: "100vw",
        zIndex: 2,
        // display: "flex",
        cursor: "grab",
    }}>
        {project.tourDisclaimer && hive.getSceneById(activeScene.get()).type && <>
            <div style={{
                position: "absolute",
                left: width.get() * 0.2,
                top: height.get() * 0.020,
                color: `#${mainColor.get()}`,
                fontSize: height.get() * 0.023,
            }}>
                {activeScene.get() && <>
                    Visite virtuelle d'un logement type T{hive.getSceneById(activeScene.get()).type}
                </>}
            </div>
        </>}

        <TourEmbed compas={setCompass} mainColor={mainColor.get()} tourId={tour.get() as string}></TourEmbed><br />
    </div>
    </>
}

export default Tour;