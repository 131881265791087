import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"

function BotMenu() {
    const { isMobile, height, botMenu, mainColor, activeLayout, activeScene } = useState(hive.state)

    return <div style={{
        position: "absolute",
        zIndex: 2,
    }}>
        <div style={{
            position: "absolute",
            width: "100vw",
            height: height.get() * 0.1 + "px",
            // background: "green",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pointerEvents: "none"
        }}>
            <div style={{
                height: height.get() * 0.1 * 0.7 + "px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
            }}>
                {botMenu.get().map((item, i) => <div key={i}
                    onClick={() => {
                        hive.action(item.action, item.target)
                    }}
                    style={{
                        pointerEvents: "initial",
                        width: height.get() * 0.21 + 'px',
                        height: height.get() * 0.1 * 0.7 + 'px',
                        lineHeight: height.get() * 0.1 * 0.7 + 'px',
                        background: item.action === "setScene" ? (item.target !== activeScene.get() ? "#cccccc" : `#${mainColor.get()}`) : (item.target !== activeLayout.get() ? '#cccccc' : `#${mainColor.get()}`),
                        marginLeft: height.get() * 0.1 * 0.7 * 0.1 + "px",
                        marginRight: height.get() * 0.1 * 0.7 * 0.1 + "px",
                        textAlign: "center",
                        fontSize: height.get() * 0.1 * 0.9 * 0.31 + "px",
                        color: "white",
                        cursor: item.action === "setScene" ? (item.target !== activeScene.get() ? 'pointer' : `initial`) :(item.target !== activeLayout.get() ? 'pointer' : `initial`),
                        userSelect: "none",
                    }}>
                    {item.text}
                </div>)}
            </div>
        </div>

    </div>
}

export default BotMenu